import React, { useEffect, useState } from 'react'
import { Link } from '@mui/material'
import isEqual from 'lodash/isEqual'
import { shallow } from 'zustand/shallow'
import { Alert, Button, Snackbar, Tooltip } from '@mui/material'
import { useRouter } from 'next/router'
import { Spinner } from 'src/components/spinner'
import { SNACKBAR_PROPS } from 'src/common/constants/ui-components.constants'
import { strings } from 'src/common/constants/strings'
import { routes } from 'src/common/constants/routes.constants'
import { useWFVersions, Version } from '../../../../hooks/useWFVersions'
import { useWFId } from '../../../../hooks/useWFId'
import { useSetWFVersion } from '../../../../hooks/useSetWFVersion'
import { useWFVersion } from '../../../../hooks/useWFVersion'
import Icon from '../../../../../../components/Icon'
import useStore from '../../../../wfe.store'
import WFName from '../WFName'
import WorkflowVersions from './WorkflowVersions'
import styles from './style.module.css'

const i18n = strings.workflows

const selector = (state: any) => ({
  workflow: state.workflow,
  workflowExecution: state.workflowExecution,
  setActiveWorkflowVersion: state.setActiveWorkflowVersion,
  activeWorkflowVersion: state.activeWorkflowVersion
})

type LeftPartProps = {
  hasWorkflowNameError: boolean
  setHasWorkflowNameError: (value: boolean) => void
  isEditable: boolean
}

const LeftPart = ({ isEditable, hasWorkflowNameError, setHasWorkflowNameError }: LeftPartProps) => {
  const wfid = useWFId()
  const { workflow, setActiveWorkflowVersion, activeWorkflowVersion } = useStore(selector, shallow)
  const { data: versionItems, isError } = useWFVersions(wfid)
  const [versions, setVersions] = useState<Version[]>([])
  const [message, setMessage] = useState<string>('')
  const [currentVersion, setCurrentVersion] = useState<Version | null>(null)
  const { data: versionWorkflow } = useWFVersion(wfid, activeWorkflowVersion)
  const { mutate: setWFVersion, isLoading: isSetWFVersionLoading } = useSetWFVersion(wfid)
  const router = useRouter()
  useEffect(() => {
    if (!isError && !isEqual(versionItems, versions)) {
      setVersions(versionItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionItems, isError])

  useEffect(() => {
    if (!isError && activeWorkflowVersion && versions.length) {
      const version = versions.find(({ VersionId }) => activeWorkflowVersion === VersionId) || versions[0]
      setCurrentVersion(version)
    } else if (
      versions.length &&
      (currentVersion?.VersionId !== versions[0]?.VersionId || currentVersion?.name !== versions[0]?.name)
    ) {
      setCurrentVersion(versions[0])
    }
  }, [isError, versions, currentVersion, activeWorkflowVersion])

  const onRestoreVersion = () => {
    if (wfid && currentVersion?.VersionId) {
      setWFVersion(currentVersion?.VersionId, {
        onError: () => setMessage(i18n.messages.setVersionError),
        onSuccess: () => {
          setActiveWorkflowVersion('')
          setMessage(i18n.messages.setVersionSuccess)
        }
      })
    }
  }

  const shouldShowRestoreVersion = currentVersion?.VersionId && currentVersion?.VersionId !== versions[0]?.VersionId

  if (isError) {
    return <div className={styles.leftPart} />
  }

  return (
    <div className={styles.leftPart}>
      <Tooltip title={i18n.exitEditor} placement='bottom'>
        <Link
          onMouseDown={() => {
            router.push(routes.workflows.path)
          }}
        >
          <Icon
            className={styles.closeIcon}
            backgroundHeight={hasWorkflowNameError ? 63 : 48}
            backgroundWidth={hasWorkflowNameError ? 63 : 48}
            name='close'
            height={24}
            width={24}
            color={'var(--customColors-neutralBrandBlue40)'}
            hoverColor='var(--primaryBlueBrand)'
            containerStyle={{ borderRight: '1px solid var(--neutralBrandBlue10)' }}
          />
        </Link>
      </Tooltip>

      {!!workflow && (
        <>
          <div className={styles.leftPart}>
            <WFName
              isEditable={isEditable}
              hasWorkflowNameError={hasWorkflowNameError}
              setHasWorkflowNameError={setHasWorkflowNameError}
            />
            {isEditable && !!versions.length && (
              <>
                <WorkflowVersions
                  currentVersion={currentVersion}
                  setCurrentVersion={setCurrentVersion}
                  setActiveWorkflowVersion={setActiveWorkflowVersion}
                  setMessage={setMessage}
                  setVersions={setVersions}
                  versions={versions}
                  workflow={workflow}
                />
                {shouldShowRestoreVersion && (
                  <div>
                    <Button
                      variant='contained'
                      disabled={!versionWorkflow}
                      color='primary'
                      onClick={onRestoreVersion}
                      className={styles.restoreVersion}
                    >
                      {i18n.restoreVersion} {isSetWFVersionLoading && <Spinner size='small' variant='secondary' />}
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      <Snackbar
        open={!!message}
        onClose={() => setMessage('')}
        {...SNACKBAR_PROPS}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          variant='filled'
          severity={message?.includes('Error') ? 'error' : 'success'}
          onClose={() => setMessage('')}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default LeftPart
