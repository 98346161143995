import React, { useState } from 'react'
import cx from 'classnames'
import Icon, { IconProps } from '../Icon'
import withHover, { WithHoverProps } from '../WithHover'
import styles from './index.module.css'

type ButtonVariants = 'primary' | 'secondary' | 'tertiary'

export type ButtonProps = {
  iconProps?: IconProps
  rightIconProps?: IconProps
  isActive?: boolean
  isDisabled?: boolean
  onClick?: (e?: any) => void
  title?: string
  variant?: ButtonVariants
  minWidth?: string | number
  children?: Element | any
  withHoverProps?: WithHoverProps
}

const Button = ({
  iconProps,
  rightIconProps,
  isActive,
  isDisabled,
  onClick,
  title,
  variant = 'primary',
  minWidth,
  children = null,
  withHoverProps
}: ButtonProps) => {
  const [isHovering, setIsHovering] = useState(false)
  const ButtonComponent = (
    <button
      className={cx(styles.button, styles[variant], {
        [styles.active]: isActive,
        [styles.disabled]: isDisabled
      })}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{ minWidth }}
      onClick={!isDisabled ? onClick : undefined}
    >
      {iconProps && <Icon {...{ height: 16, width: 16, ...iconProps }} />}
      {title}
      {rightIconProps && <Icon {...{ height: 16, width: 16, ...rightIconProps }} />}
      {children}
    </button>
  )

  if (withHoverProps) {
    const ButtonWithHover = withHover({
      ...withHoverProps,
      isShowHoverComponent: isHovering,
      ComponentToHover: ButtonComponent
    })

    return <ButtonWithHover />
  }

  return ButtonComponent
}

export default Button
