import styles from '../../components/RunningWorkflowDetails/index.module.css'
import { WorkflowExecutionStatus } from '../../modules/wfe/wfe.constants'
import { SingleStepExecutionsStatuses } from '../../modules/wfe/wfe.types'

export const RunningStatusIconPropsEnum = {
  SUCCESS: { name: 'success', height: 18, width: 18 },
  FAIL: { name: 'fail', height: 18, width: 18 },
  PENDING: { name: 'pending', height: 18, width: 18 },
  RUNNING: {
    name: 'icons/monitoring/running',
    className: styles.rotatingSvg,
    style: { scale: 0.97 }
  }
}
export const IconsPropsByStatus: any = {
  [WorkflowExecutionStatus.Pending]: RunningStatusIconPropsEnum.PENDING,
  [WorkflowExecutionStatus.Succeeded]: RunningStatusIconPropsEnum.SUCCESS,
  [WorkflowExecutionStatus.Failed]: RunningStatusIconPropsEnum.FAIL,
  [WorkflowExecutionStatus.Aborted]: RunningStatusIconPropsEnum.FAIL,
  [WorkflowExecutionStatus.Running]: RunningStatusIconPropsEnum.RUNNING,
  [WorkflowExecutionStatus.Waiting]: RunningStatusIconPropsEnum.PENDING,
  [WorkflowExecutionStatus.RunningSteps]: RunningStatusIconPropsEnum.RUNNING,
  [WorkflowExecutionStatus.OK]: RunningStatusIconPropsEnum.SUCCESS,
  [SingleStepExecutionsStatuses.FAIL]: RunningStatusIconPropsEnum.FAIL
}

export const FailedStatuses: string[] = [WorkflowExecutionStatus.Failed, SingleStepExecutionsStatuses.FAIL]

export const PendingStatuses: string[] = [WorkflowExecutionStatus.Waiting, 'PENDING']
