import { TriggerTypes } from 'src/modules/wfe/components/StepBar/TriggerType/types'

export enum WorkflowRunStatuses {
  'SUCCEEDED' = 'SUCCEEDED',
  'SUCCEEDED_WITH_ERRORS' = 'SUCCEEDED_WITH_ERRORS',
  'FAILED' = 'FAILED',
  'PARTIALLY' = 'PARTIALLY',
  'ABORTED' = 'ABORTED',
  'TIMED_OUT' = 'TIMED_OUT',
  'RUNNING' = 'RUNNING',
  'WAITING' = 'WAITING'
}

export type WorkflowRunStatus = keyof typeof WorkflowRunStatuses

export type WorkflowRunRow = {
  id: string
  status: WorkflowRunStatus
  eventSource: keyof typeof TriggerTypes
  startDate: string
  stopDate: string
  executionId: string
  stateMachineId: string
  workflowName: string
  workflowId: string
}
