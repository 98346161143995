import Link from 'next/link'
import { useRouter } from 'next/router'
import cx from 'classnames'
import { Box, IconButton, Drawer } from '@mui/material'
import { useState } from 'react'
import Image from 'next/image'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { NavLink } from 'src/common/layouts/types'
import { Accordion } from '../../Accordion'
import styles from './styles.module.css'

type Props = {
  navItems: NavLink[]
  isOpen: boolean
  onToggle: () => void
}

export const collapsedSidebarWidth = 64
export const expandedSidebarWidth = 234

export const NavBar = ({ navItems, onToggle, isOpen }: Props) => {
  const [subLinksAccordion, setSubLinksAccordion] = useState<Record<string, boolean>>({})
  const router = useRouter()

  const sidebarWidth = isOpen ? `${expandedSidebarWidth}px !important` : `${collapsedSidebarWidth}px !important`

  return (
    <Drawer
      variant='permanent'
      className={styles.navBar}
      open={isOpen}
      sx={{
        width: sidebarWidth,
        '& .MuiDrawer-paper': {
          width: sidebarWidth
        }
      }}
    >
      <Image className={styles.logo} src={'/images/logo.svg'} alt='wiv' width={24} height={24} />
      {navItems.map(navItem => {
        const { title, path, icon, subLinks } = navItem

        const subNavItems = subLinks || []
        const isActiveSection = [path, ...subNavItems.map(i => i.path)].some(item => router.pathname.includes(item))
        const hasSubNavItems = !!subNavItems.length
        const isExpanded = subLinksAccordion[title]

        const handleRedirect = () => {
          if (!hasSubNavItems) {
            router.push(path)
          } else if (!isOpen) {
            setSubLinksAccordion({ [title]: true })
            onToggle()
          }
        }

        return (
          <Accordion
            key={title}
            title={
              <div onClick={handleRedirect}>
                <Image src={icon} alt={title} width={24} height={24} />
                {isOpen && title}
              </div>
            }
            className={styles.navItem}
            styles={{
              '& .MuiAccordionSummary-root': {
                ...(isActiveSection && (!isOpen || !isExpanded) && { backgroundColor: 'var(--lightPink) !important' })
              },
              '&:hover': {
                ...(!isActiveSection &&
                  (!hasSubNavItems || !isExpanded) && { backgroundColor: 'rgba(255, 255, 255, 0.1)' })
              },
              '& .MuiAccordionSummary-expandIconWrapper': {
                display: isOpen && hasSubNavItems ? 'flex' : 'none'
              }
            }}
            expanded={!!isExpanded && isOpen}
            setExpanded={expanded => {
              if (hasSubNavItems) {
                setSubLinksAccordion(prev => ({ ...prev, [title]: expanded }))
              }
            }}
          >
            <>
              {subNavItems.map(subNavItem => (
                <Link key={subNavItem.title} href={subNavItem.path ?? ''}>
                  <Box
                    className={cx(styles.link, {
                      [styles.linkActive]: router.pathname.includes(subNavItem.path)
                    })}
                  >
                    {subNavItem.title}
                  </Box>
                </Link>
              ))}
            </>
          </Accordion>
        )
      })}

      <IconButton className={styles.navBarToggleIcon} onClick={onToggle}>
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </Drawer>
  )
}
