import { NavLink } from 'src/common/layouts/types'
import { Permission } from 'src/common/auth/constants'

export const routes = {
  home: {
    path: '/home',
    permission: Permission['home:read']
  },
  opportunities: {
    path: '/cases/all',
    firstPagePath: '/cases',
    permission: Permission['recommendations:read']
  },
  boards: {
    path: '/boards',
    permission: Permission['recommendations:read']
  },
  casesConfiguration: {
    flag: 'casesconfigurations',
    path: '/cases-configuration',
    permission: Permission['recommendations:read']
  },
  dashboards: {
    path: '/dashboards',
    permission: Permission['dashboards:read']
  },
  workflows: {
    path: '/workflows',
    permission: Permission['wf:read']
  },
  wfe: {
    path: '/wfe',
    permission: Permission['wf:read']
  },
  templates: {
    path: '/templates',
    permission: Permission['templates:read']
  },
  datastores: {
    path: '/datastores',
    permission: Permission['datastores:read']
  },
  runs: {
    path: '/runs',
    permission: Permission['activityLog:read']
  },
  approvals: {
    path: '/approvals',
    permission: Permission['approvals:read']
  },
  integrations: {
    path: '/integrations',
    permission: Permission['integrations:read']
  },
  forms: {
    path: '/forms'
  }
}

export const navigationItems = [
  {
    icon: '/images/icons/main/home.svg',
    title: 'Home',
    ...routes.home
  },
  {
    title: 'Savings',
    icon: '/images/icons/main/savings.svg',
    subLinks: [
      {
        title: 'Cases',
        icon: '/images/icons/main/opportunities.svg',
        ...routes.boards
      },
      {
        title: 'Configuration',
        icon: '/images/icons/main/cases-configuration.svg',
        ...routes.casesConfiguration,
        flag: 'casesconfigurations'
      }
    ]
  },
  {
    title: 'Automation',
    icon: '/images/icons/main/workflows.svg',
    subLinks: [
      {
        title: 'Workflows',
        icon: '/images/icons/main/workflows.svg',
        ...routes.workflows
      },
      {
        title: 'Templates',
        icon: '/images/icons/main/templates.svg',
        ...routes.templates
      },
      {
        title: 'Runs',
        icon: '/images/icons/main/runs.svg',
        ...routes.runs
      },
      {
        title: 'Approvals',
        icon: '/images/icons/main/approvals.svg',
        ...routes.approvals
      }
    ]
  },
  {
    title: 'Data',
    icon: '/images/icons/main/data.svg',
    subLinks: [
      {
        title: 'Dashboards',
        icon: '/images/icons/main/dashboards.svg',
        ...routes.dashboards
      },
      {
        title: 'Datastores',
        icon: '/images/icons/main/datastores.svg',
        ...routes.datastores
      }
    ]
  },
  {
    title: 'Integrations',
    icon: '/images/icons/main/integrations.svg',
    ...routes.integrations
  }
] as NavLink[]

export const casesByBoardPath = (boardId: string) => {
  return routes.opportunities.firstPagePath + '/' + boardId
}
