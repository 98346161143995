import { valueHasOutput } from './components/StepBar/outputTree.utils'
import { Parameter } from './wfe.types'

// convert special steps from UI <> BE

export const keyValueByTriggerToCore = (param: Parameter) => {
  return { ...param, value: typeof param.value === 'object' ? JSON.stringify(param.value) : param.value }
}

export const datastoreFilterToCore = (param: Parameter & { value: any }) => {
  const filterValueField = param?.value?.value

  if (typeof filterValueField === 'string') {
    // "\"77\""->"77" let user type "77" as a string in slate editor
    const trimmedValue = (filterValueField as string).replace(/"/g, '') as any
    const parsedValueField = isNaN(trimmedValue) ? filterValueField : trimmedValue

    return {
      ...param,
      value: {
        ...param.value,
        value: parsedValueField
      }
    }
  }

  return param
}

export const filterBuilderToCore = (param: any) => {
  if (param.value === "[': ']") {
    // todo: filter builder should use object, not parsing
    return { ...param, value: '' }
  }

  return param
}

export const multiSelectToCore = (param: any) => {
  const value = param.value.values?.[0] ?? ''
  if (valueHasOutput(value)) {
    return {
      ...param,
      value: {
        process_all: false,
        values: value,
        excluded: param.value?.excluded ?? false
      }
    }
  }

  return param
}

export const keyValueByTriggerToUI = (param: Parameter) => {
  return { ...param, value: !!param.value && typeof param.value === 'string' ? JSON.parse(param.value ?? {}) : {} }
}

export const multiSelectToUI = (param: any) => {
  if (param.value?.values && typeof param.value?.values === 'string') {
    return {
      ...param,
      value: {
        process_all: false,
        values: [param.value?.values],
        excluded: param.value?.excluded ?? false
      }
    }
  }

  return param
}

export const timePeriodCompactToCore = (value: string) => {
  const getType = () => {
    if (!value) {
      return 'LAST_6_MONTHS'
    }

    if (value.includes('1 day ago')) {
      return 'LAST_24_HOURS'
    } else if (value.includes('1 week ago')) {
      return 'LAST_7_DAYS'
    } else if (value.includes('1 month ago')) {
      return 'LAST_30_DAYS'
    } else if (value.includes('3 months ago')) {
      return 'LAST_3_MONTHS'
    } else if (value.includes('6 months ago')) {
      return 'LAST_6_MONTHS'
    } else if (value.includes('PREV_MONTH')) {
      return 'PREV_MONTH'
    } else if (value.includes('LAST_YEAR')) {
      return 'LAST_YEAR'
    }

    return 'CUSTOM'
  }

  const type = getType()

  const getDates = () => {
    if (type === 'CUSTOM') {
      const regex = /Start=(\d{4}-\d{2}-\d{2}),End=(\d{4}-\d{2}-\d{2})/
      const matches = value.match(regex)
      if (matches) {
        return [matches[1], matches[2]]
      }
    }

    return ['', '']
  }

  const dates = getDates()

  return {
    type,
    start_date: dates[0],
    end_date: dates[1]
  }
}

export const timePeriodCompactToUI = (param: Parameter) => {
  const value = param?.value as any

  if (!value?.type) {
    return param
  }

  const getType = () => {
    if (value.type === 'LAST_24_HOURS') {
      return '1 day ago'
    } else if (value.type === 'LAST_7_DAYS') {
      return '1 week ago'
    } else if (value.type === 'LAST_30_DAYS') {
      return '1 month ago'
    } else if (value.type === 'LAST_3_MONTHS') {
      return '3 months ago'
    } else if (value.type === 'LAST_6_MONTHS') {
      return '6 months ago'
    } else if (value.type === 'PREV_MONTH' || value.type === 'LAST_YEAR') {
      return value.type
    }

    return 'CUSTOM'
  }

  const type = getType()

  if (type === 'CUSTOM') {
    return { ...param, value: `Start=${value.start_date},End=${value.end_date}` }
  }

  return { ...param, value: `Start=$(date -d "${type}" +%Y-%m-%d),End=$(date +%Y-%m-%d)` }
}
