import React from 'react'
import { Tooltip } from '@mui/material'
import _truncate from 'lodash/truncate'

type TruncateTitleProps = {
  title: string
  maxLength?: number
  className?: string
  placement?: 'top' | 'bottom' | 'left' | 'right'
  arrow?: boolean
  tooltipMinLength?: number
}

const TruncateTitle = ({
  arrow = true,
  className = '',
  title = '',
  maxLength = 40,
  placement = 'top',
  tooltipMinLength
}: TruncateTitleProps) => {
  return (
    <Tooltip arrow={arrow} placement={placement} title={title.length > (tooltipMinLength || maxLength) ? title : ''}>
      <span className={className}>{_truncate(title, { length: maxLength })}</span>
    </Tooltip>
  )
}

export default TruncateTitle
