import { useNavigationLinks } from 'src/components/navigation/vertical'
import { NavBar as NavBarComponent } from './NavBar'

type Props = {
  isOpen: boolean
  onToggle: () => void
}

export const NavBar = (props: Props) => {
  const navItems = useNavigationLinks()

  return <NavBarComponent navItems={navItems} isOpen={props.isOpen} onToggle={props.onToggle} />
}
