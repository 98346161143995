var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/sentry";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b326cc649d154c153a055fbf25a815220d0deb34"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { SENTRY_CONSTANTS } from './sentry.constants'

export const sentryInit = async ({
  user,
  disable_session_recording
}: {
  user: {
    userId: string
    email: string
  }
  disable_session_recording: boolean
}) => {
  await Sentry.init({
    ...SENTRY_CONSTANTS,

    replaysOnErrorSampleRate: disable_session_recording ? 0 : 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: disable_session_recording
      ? 0
      : Number(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SAMPLE_RATE),

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      new Sentry.Replay({
        // Additional Replay configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: process.env.NEXT_PUBLIC_APP_ENV !== 'development'
      })
    ]
  })

  Sentry.configureScope(scope => {
    scope.setUser({ id: user?.userId, email: user?.email })
  })
}
