export const QUERY_KEYS = {
  tableConfiguration: 'table-configuration',
  recommendations: 'recommendations',
  recommendation: 'recommendation',
  recommendationsFilters: 'recommendations-filters',
  teams: 'teams',
  teamsMembers: 'teams-members',
  teamsChannels: 'teams-channels',
  workflows: 'workflows',
  integrations: 'integrations',
  events: 'events',
  tags: 'tags',
  casesConfiguration: 'cases-configuration',
  boards: 'boards',
  boardsCostImpact: ['boards-cost-impacts'],
  monitoring: 'monitoring'
}
