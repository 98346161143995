import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import cx from 'classnames'
import { Tooltip, TooltipProps } from '@mui/material'
import Icon, { IconProps } from '../Icon'
import { useOnKeyDown } from '../../common/hooks/common'
import TruncateTitle from '../TruncateTitle'
import styles from './styles.module.css'

const DISABLED_COLOR = 'var(--neutralBrandBlue20)'
const DEBOUNCE_TIME = 300
const editableIconProps = { name: 'icons/svg/edit', height: 24, width: 24 }

type TextProps = {
  className?: string
  title?: string | number | JSX.Element
  color?: string
  fontSize?: string | number
  fontWeight?: number
  typeVariant?: any // TypeVariantsText
  sizeVariant?: any // SizeVariantsText
  style?: CSSProperties
  iconProps?: IconProps
  onClick?: (e?: any) => void
  hoverColor?: string
  isTextFirst?: boolean
  tooltipProps?: Partial<TooltipProps>
  isEditable?: boolean
  onChange?: (e: any) => void
  errorMessage?: string
  debounceTime?: number
  maxLength?: number
  isUnderlined?: boolean
  isDisabled?: boolean
}

export const SizeVariantsText = {
  XSMALL: {
    fontSize: '12px',
    fontWeight: 400
  },
  SMALL: {
    fontSize: '14px',
    fontWeight: 400
  },
  MEDIUM: {
    fontSize: '16px',
    fontWeight: 400
  },
  LARGE: {
    key: 'large',
    fontSize: '20px',
    fontWeight: 400
  },
  XLARGE: {
    fontSize: '24px',
    fontWeight: 700
  }
}

export const TypeVariantsText = {
  DEFAULT: {
    key: 'default',
    color: 'var(--primaryBlueBrand)'
  },
  PRIMARY: {
    key: 'primary',
    color: 'var(--customColors-secondaryDarkPurple)'
  },
  SECONDARY: {
    color: 'var(--customColors-neutralBrandBlue30)'
  }
}

const isPrimaryLarge = (size: string, type: string) =>
  type === TypeVariantsText.PRIMARY.key && size === SizeVariantsText.LARGE.key

const Text = ({
  className = '',
  title,
  style,
  color,
  fontSize,
  fontWeight,
  typeVariant = TypeVariantsText.DEFAULT,
  sizeVariant = SizeVariantsText.SMALL,
  iconProps,
  onClick,
  hoverColor,
  isTextFirst,
  tooltipProps,
  isEditable,
  onChange,
  errorMessage,
  debounceTime = DEBOUNCE_TIME,
  maxLength,
  isUnderlined,
  isDisabled
}: TextProps) => {
  const [value, setValue] = useState(`${title}`)
  const [isEditing, setIsEditing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const size = isPrimaryLarge(sizeVariant.key, typeVariant.key) ? { ...sizeVariant, fontWeight: 500 } : sizeVariant
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  useOnKeyDown(
    {
      Enter: () => !!title && setIsEditing(false),
      Escape: () => !!title && setIsEditing(false)
    },
    isEditing
  )

  const handleOnChange = (e: any) => {
    const value = e.target.value
    setValue(value)
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      onChange?.(value)
    }, debounceTime)
  }

  const textStyle = {
    ...size,
    ...typeVariant,
    fontSize: fontSize || size.fontSize,
    fontWeight: fontWeight || size.fontWeight,
    color: color || typeVariant.color,
    ...style
  }

  return (
    <Tooltip {...tooltipProps} title={tooltipProps?.title}>
      <div
        className={cx(styles.textContainer, {
          [styles.hasError]: !!errorMessage,
          [styles.disabled]: isDisabled
        })}
      >
        {isEditing || (!title && isEditable) ? (
          <input
            className={cx(styles.input, {
              [styles.editing]: isEditing
            })}
            style={{ ...textStyle, width: `${`${title}`.length || 2}ch` }}
            autoFocus
            type='text'
            value={value}
            onChange={e => handleOnChange(e)}
            onBlur={() => setIsEditing(false)}
            onFocus={() => setIsEditing(true)}
          />
        ) : (
          <span
            className={classNames(className, styles.text, {
              [styles.clickable]: !!onClick && !isDisabled,
              [styles.textFirst]: isTextFirst || isEditable,
              [styles.editable]: isEditable,
              [styles.underline]: isUnderlined
            })}
            onClick={isEditable ? () => setIsEditing(true) : !isDisabled ? (e: any) => onClick?.(e) : undefined}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{
              ...textStyle,
              color: isDisabled ? DISABLED_COLOR : hoverColor && isHovering ? hoverColor : color || typeVariant.color
            }}
          >
            {(isEditable || iconProps) && (
              <Icon
                {...(iconProps || editableIconProps)}
                color={isHovering ? hoverColor : iconProps?.color}
                hoverColor={isHovering ? hoverColor : undefined}
                isDisabled={isDisabled}
              />
            )}
            {maxLength ? <TruncateTitle title={title as string} maxLength={maxLength} /> : <span>{title}</span>}
          </span>
        )}
        {<div className={styles.errorMessage}>{value && errorMessage}</div>}
      </div>
    </Tooltip>
  )
}

export default Text
